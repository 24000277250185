<template>
  <div class="enterprise-role-management-con">
    <RoleListPage
      roleType="企业角色"
      :roleList="enterpriseRoleList"
      @selectRole="selectRole"/>
    <PermissionPage
      v-if="isPermissionPageShow"
      :permissionList="selectedRole.permissionList"
      :roleType="selectedRole.title"
      @closePage="closePage"/>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import PermissionPage from '@/components/admin/PermissionPage.vue'
import RoleListPage from '@/components/admin/RoleListPage.vue'

export default defineComponent({
  components: {
    PermissionPage,
    RoleListPage
  },
  props: {

  },
  setup (props) {
    const state = reactive({
      selectedRole: {},
      enterpriseRoleList: [
        {
          title: '企业管理员',
          isDefault: true,
          permissionList: [
            {
              title: '企业操作',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '修改企业信息',
                  checked: false
                },
                {
                  title: '删除企业',
                  checked: false
                }
              ]
            },
            {
              title: '企业成员管理',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '创建项目',
                  checked: false
                },
                {
                  title: '查看所有项目',
                  checked: false
                },
                {
                  title: '删除项目',
                  checked: false
                }
              ]
            },
            {
              title: '权限角色管理',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '创建项目',
                  checked: false
                },
                {
                  title: '查看所有项目',
                  checked: false
                },
                {
                  title: '删除项目',
                  checked: false
                }
              ]
            }
          ]
        },
        {
          title: '普通成员',
          canSetDefault: true,
          permissionList: [
            {
              title: '企业操作',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '修改企业信息',
                  checked: false
                },
                {
                  title: '删除企业',
                  checked: false
                }
              ]
            },
            {
              title: '企业成员管理',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '创建项目',
                  checked: false
                },
                {
                  title: '查看所有项目',
                  checked: false
                },
                {
                  title: '删除项目',
                  checked: false
                }
              ]
            },
            {
              title: '权限角色管理',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '创建项目',
                  checked: false
                },
                {
                  title: '查看所有项目',
                  checked: false
                },
                {
                  title: '删除项目',
                  checked: false
                }
              ]
            }
          ]
        },
        {
          title: '管理员',
          permissionList: [
            {
              title: '企业操作',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '修改企业信息',
                  checked: false
                },
                {
                  title: '删除企业',
                  checked: false
                }
              ]
            },
            {
              title: '企业成员管理',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '创建项目',
                  checked: false
                },
                {
                  title: '查看所有项目',
                  checked: false
                },
                {
                  title: '删除项目',
                  checked: false
                }
              ]
            },
            {
              title: '权限角色管理',
              checkAll: false,
              indeterminate: false,
              chlidren: [
                {
                  title: '创建项目',
                  checked: false
                },
                {
                  title: '查看所有项目',
                  checked: false
                },
                {
                  title: '删除项目',
                  checked: false
                }
              ]
            }
          ]
        }],
      isPermissionPageShow: false
    })

    onMounted(async () => {

    })

    const selectRole = (item) => {
      state.isPermissionPageShow = true
      state.selectedRole = item
    }

    const closePage = () => {
      state.isPermissionPageShow = false
    }

    return {
      selectRole,
      closePage,
      ...toRefs(state)
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .enterprise-role-management-con {
    width: 100%;
    height: 100%;
    position: relative;
  }
</style>
