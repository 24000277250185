<template>
  <div class="enterprise-role">
    <div class="enterprise-role-header">
      <div class="header-title" @click="closePage">
        <span class="title-icon"><ArrowLeftOutlined /></span>
        <span class="title-text">{{roleType}}</span>
      </div>
      <div class="delete-role">
        删除角色
      </div>
    </div>
    <div class="enterprise-role-content">
      <div
        class="permission-main"
        v-for="(item, index) in permissionList"
        :key="index">
        <div class="permission-title">
          <a-checkbox
            v-model:checked="item.checkAll"
            :indeterminate="item.indeterminate"
            @change="onClickMainChange(item)">
            {{item.title}}
          </a-checkbox>
        </div>
        <div class="permission-conent">
          <div
            class="permission-content-item"
            v-for="(content, cIndex) in item.chlidren"
            :key="cIndex">
            <div class="permission-title">
              <a-checkbox
                v-model:checked="content.checked"
                @change="onClickContentChange(item, content)">
                {{content.title}}
              </a-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'PermissionPage',
  components: {
    ArrowLeftOutlined
  },
  props: {
    permissionList: {
      type: Array,
      default: () => []
    },
    roleType: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const state = reactive({

    })
    onMounted(async () => {

    })

    const onClickMainChange = (main) => {
      main.indeterminate = false
      console.log(main)
      main.chlidren.map(i => {
        i.checked = main.checkAll
      })
    }

    const onClickContentChange = (main, content) => {
      let count = 0
      main.chlidren.map(i => {
        if (i.checked) {
          count += 1
        }
      })
      if (count === 0) {
        main.checkAll = false
        main.indeterminate = false
      } else if (count < main.chlidren.length) {
        main.checkAll = false
        main.indeterminate = true
      } else {
        main.checkAll = true
        main.indeterminate = false
      }
    }
    const closePage = () => {
      context.emit('closePage')
    }

    return {
      ...toRefs(state),
      closePage,
      onClickMainChange,
      onClickContentChange
    }
  }
})
</script>
<style lang='less' scoped>
.enterprise-role {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: white;
    overflow: hidden;
}
.enterprise-role-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    /* font-size: 16px; */
    border-bottom: solid 1px rgba(0,0,0,0.1);
    /* border-style: none none solid; */
    /* font-weight: 600; */
}
.header-title {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}
span.title-icon {
    margin-right: 6px;
}
.delete-role {
    cursor: pointer;
    /* font-size: 14px; */
    color: #FF536A;
    /* font-weight: 400; */
}
.enterprise-role-content {
  overflow: auto;
  height: calc(100% - 58px);
}
.permission-main {
    padding-top: 24px;
    border-bottom: solid 1px rgba(0,0,0,0.1);
}
.permission-title {
    margin-bottom: 24px;
}
.permission-conent {
    display: flex;
    flex-wrap: wrap;
}
.permission-content-item {
    /* flex: 1; */
    /* width: 49%; */
    margin-right: 27px;
    width: 128px;
}
</style>
