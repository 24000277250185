<template>
  <div style="height:100%;overflow:hidden">
    <div class="role-list-header">
      <div class="role-list-header-title">
        {{roleType}}
      </div>
      <div class="add-role">
        <span class="action-icon"><PlusCircleOutlined /></span>
        <span>添加角色</span>
      </div>
    </div>
    <div class="role-list-content">
      <div
        class="content-item"
        v-for="(item, index) in roleList"
        :key="index"
        @click="selectRole(item)">
        <div class="item-title">
          {{item.title}}
        </div>
        <div
          v-if="item.isDefault"
          class="project-default">
          项目默认角色
        </div>
        <div
          v-if="item.canSetDefault"
          class="project-set-default">
          设为默认角色
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  name: 'RoleListPage',
  components: {
    PlusCircleOutlined
  },
  props: {
    roleType: {
      type: String,
      default: ''
    },
    roleList: {
      type: Array,
      default: () => []
    }
  },
  setup (props, context) {
    const state = reactive({
    })
    onMounted(async () => {

    })

    const selectRole = (item) => {
      context.emit('selectRole', item)
    }

    return {
      ...toRefs(state),
      selectRole
    }
  }
})
</script>
<style lang='less' scoped>
.role-list-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: solid 1px rgba(0,0,0,0.1);
    margin-bottom: 8px;
}
.role-list-header-title {
    font-size: 16px;
    font-weight: 600;
}
.add-role {
    color: #209FFD;
    cursor: pointer;
    span.action-icon {
        margin-right: 6px;
    }
}
.role-list-content {
    overflow: auto;
    height: calc(100% - 58px);
}
.content-item {
    padding: 16px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: solid 1px rgba(0,0,0,0.1);
    cursor: pointer;
    .project-default {
        padding: 4px;
        border-radius: 4px;
        background: rgba(0,0,0,0.05);
        color: rgba(0,0,0,0.4);
        font-size: 12px;
    }
    .project-set-default {
        cursor: pointer;
        color: #209FFD;
    }
}
</style>
